
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqsListModule from '@/store/modules/tasqsListModule';

@Component({
  components: {
    TasqHistory: () => getComponent('tasqs/TasqHistory'),
  },
})
export default class TasqWellDetails extends Vue {
  @Prop({ required: true }) tasq!: TasqJob;

  HEADER_TAB = 'Data entry';

  HISTORY_TAB = 'History';

  activeTab = this.HISTORY_TAB;

  clickTab(name): void {
    this.activeTab = name;
  }

 get activeTasq(){
  return tasqsListModule.activeTasq
 }

  get datalist() {
    return [
      {
        title: 'Pad Name',
        value: this.activeTasq ? this.activeTasq.padName:  this.tasq.padName,
      },
      {
        title: 'Route',
        value: this.activeTasq ? this.activeTasq.route : this.tasq.route,
      },
      {
        title: 'Area',
        value: this.activeTasq ? this.activeTasq.area : this.tasq.area,
      },
      {
        title: 'Team',
        value: this.activeTasq ? this.activeTasq.team  : this.tasq.team,
      },
    ];
  }
}
